const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];

globalThis.Convermax.getCategoryId = (item) => item.CategoryIDs?.[0] || 1;

globalThis.Convermax.quickview = (item) => (e) => {
  e.preventDefault();
  window.quickview(`/product.asp?lt_c=1&itemid=${item.CatalogID}&qv=1`);
};

const brandSelectionPageUrl = '/manufacturer_index.asp';

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === brandSelectionPageUrl ? 'parts' : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [{ pathname: brandSelectionPageUrl, field: 'Manufacturer' }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    // {
    //   name: 'CategoryPage',
    //   type: 'SearchPage',
    //   location: 'body.category-page .category-products, body.search-groups .content-area',
    //   template: 'SearchPage',
    // },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Name': 'A to Z',
        'Name:desc': 'Z to A',
        'Price': 'Price: Ascending',
        'Price:desc': 'Price: Descending',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    // {
    //   name: 'SearchBoxDialogButton',
    //   location: '.site-header #searchBox',
    //   template: 'searchBox/dialogButtonAdaptable',
    // },
    // {
    //   name: 'HomeVehicleWidget',
    //   type: 'VehicleWidget',
    //   location: { firstChildOf: 'body.home-page .home-page-content', class: 'container' },
    //   template: 'fitmentSearch/homeVehicleWidget',
    // },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    // {
    //   name: 'VerifyFitment',
    //   type: 'VehicleWidget',
    //   location: {
    //     insertBefore: 'body.listing-page .addToCartBlock',
    //     class: 'cm_vehicle-widget__verify-fitment_container',
    //   },
    //   template: 'fitmentSearch/verifyFitment',
    // },
    // {
    //   name: 'FacetBrandTiles',
    //   type: 'FacetTiles',
    //   location: '#manufacturerIndex .content-area',
    //   template: 'facetTiles/alphabeticalContainer',
    //   view: 'columns',
    //   visibleIf: () => window.location.pathname === brandSelectionPageUrl,
    // },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};

function updateCallback() {
  // copy-paste from shift4shop core.js
  /* eslint-disable */
  typeof lozad != 'function' &&
    (jQuery('div.product-items').each(function () {
      var n = 0;
      jQuery('.product-item', this).each(function (t, i) {
        var r = jQuery(i).height();
        r > n && (n = r);
      });
      jQuery(this).attr('data-itemsHeight', n);
    }),
    jQuery('div.product-items').each(function (n, t) {
      var i = jQuery(t).attr('data-itemsHeight');
      i != undefined && i + '' != '' && jQuery(t).find('.product-item').height(i);
    }));

  var maxHeight = 0;
  jQuery('.product-items .product-item .name').each(function () {
    if (jQuery(this).height() > maxHeight) {
      maxHeight = jQuery(this).height();
    }
  });
  jQuery('.product-items .product-item .name').height(maxHeight);
  /* eslint-enable */
}
